<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

<!--        <tchtree v-model="searchForm.class_ids" @change="getlist" :isAll="1"></tchtree>-->
        <el-input
          v-model="searchForm.news_title"
          size="small"
          placeholder="新闻标题"
          style="max-width: 200px; margin-right: 10px; margin-left: 10px"
        />
        <el-date-picker
          v-model="searchForm.release_time"
          size="small"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="max-width: 300px; margin-right: 10px; margin-left: 10px"
        >
        </el-date-picker>
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>
    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column
        prop="news_title"
        label="新闻标题"
        min-width="1000"
      ></el-table-column>

      <el-table-column
        prop="release_time"
        label="发布时间"
        min-width="120"
      ></el-table-column>
      <el-table-column prop="type" label="来源" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type == '1'">首页爬取</span>
          <span v-if="scope.row.type == '2'">后台添加</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div class="flexStart">
            <div class="flexStart">
              <el-button
                type="text"
                size="small"
                @click="findOne(scope.row)"
                style="margin: 0 !important"
              >详情
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog title="新闻详情" :visible.sync="dialogVisible" width="800px">
      <div class="newsDetail" v-if="row" v-html="row.detail"></div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      dialogVisible: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      ruleForm:{},
      row: null,
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    getlist() {
      this.$http
        .post("/api/sys_news_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          type: 1,
          news_title: this.searchForm.news_title,
          release_time: this.searchForm.release_time,
        })
        .then((res) => {
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    findOne(e) {
      this.row = e;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.newsDetail img {
  width: 100% !important;
  height: auto !important;
}
</style>
